*{
  margin: 0;
  padding: 0;
}

body {
  background: rgb(34,24,106);
  background: linear-gradient(90deg, rgba(34,24,106,1) 0%, rgba(0,0,0,1) 50%);
  backdrop-filter: blur(10px);
}

/* top list code  */
.topList { 
    text-transform: capitalize;
    background-color: transparent ;
    top: 0%;
    left: 0%;
    right: 0%;
    display: inline-flex;
    height: 40px;
    margin-left: 48%;
}

.ul-list {
  color: white;
  text-align: end;
  margin-bottom: 2rem;
}

li{
  color: white;
  list-style-type: none;
  letter-spacing: 2px;
  display: inline-flex;
  padding: 10px;
  font-family: 'Patrick Hand SC', cursive;
  margin-inline-end: 30px;
}

li:hover {
 border-bottom: 1px solid white;
}

li:active{
  color: greenyellow;
}

/* font for .header */
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap');
.header{
  text-align: center;
  text-transform: capitalize;
  font-family: 'Patrick Hand SC', cursive;
  color: white;
  text-indent: 26px;
  display: inline-flex;
  width: 12%;
  margin-top: 24px;
  letter-spacing: 2px;
  
} 

/* neonImage scomponents */
.neonimage{
  margin-left: 70%;
  margin-top: 10%;
  display: inline-flexbox;
}

@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
.totalQuotes{
  margin-top: 4%;
  display: inline-flexbox;
 }

/* quotes components  */
.quotes{
    margin-top:-19.5rem;
    position: absolute;
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 0%;
    margin-right: 10%;
    display:inline-flex;
    margin-bottom: 10px;
    margin-left: 10%;
    color: white;
    letter-spacing: 3px;
}


/* quotes components  */
.quotes-secound{
    margin-top:-5.5rem;
    position: absolute;
    width: 40%;
    text-align:left;
    font-family: 'Josefin Sans', sans-serif;
    margin-right: 10%;
    
    display:inline-flex;
    margin-bottom: 10px;
    margin-left: 10%;
    color: white;
    letter-spacing: 3px;
    text-transform: capitalize;
    line-height: 1.7rem ;
}


/* quotes for image  */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lilita+One&display=swap');
 
.content {
  justify-content: center;
  text-align: left;
  font-size: 0.65in;
  text-transform:capitalize;
  position: absolute;  
  margin-top: -16.5rem;
  margin-left: 8.3rem;
  width: 40%;
  font-family: 'Lilita One', sans-serif;
  letter-spacing: 2px;
  color: white;
  text-shadow: 4px 4px 100px whitesmoke;
}

/* qoutes company logo design  */
.image {
    margin-left: 10%; /* Optional: Add some space between the content and image */
    height: 300px;
    width:  450px;
    margin-top: 7%;
    filter: drop-shadow( 19px -10px 0px black);
  }

.image:hover{
    filter: drop-shadow( 19px -10px 0px #333);
}

.features{
    text-align:justify;
    width: 100%;
    letter-spacing: 1px;
    word-spacing: 5px;
    font-size: 20px;
    margin-left: 10px;
    text-indent: 10px;
}


/* total outline for prizing components */
.main_total{
   border:1px solid darkblue;
   border-radius: 40px;
   background-color: white;
   margin-top: 20rem;
   margin-bottom: 10rem;
   margin-left: 3rem;
   margin-right: 3rem;
   height: 35rem;
   box-shadow: -10px -10px 10px black;
  }

/* prizing input  */
.prizing {
    font-size: 16px;
    text-align:center;
    width: 10rem;
    color: black;
    border: 1px solid black;
    background-color: white;
    margin-bottom: 2rem;
    font-family: 'Josefin Sans', sans-serif;
    display: inline-block;
  }

.bold{
  color: lightgreen;
 }

.prize { 
  font-family: 'Josefin Sans', sans-serif;
  margin-left: 10px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 9rem;
  margin-left: 3rem;
  width: 19rem;
}

.totalfeet{
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  width: 30%;
  display: inline-block;
  margin-left: 10rem ;
  padding: 10px;
  display: inline-block;
  position:absolute;
}

.totalRate{
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  width: 30%;
  display: inline-block;
  padding: 10px;
  display: inline-block;
  position:absolute;
  margin-top: 10rem;
  margin-left: 10rem
}


@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lilita+One&display=swap');
.head-head-prize {
  text-transform: uppercase;
  color: white;
  letter-spacing: 4px;
  margin-left:1.5rem;
  margin-top: 2rem;
  padding: 20px;
  font-size: x-large;
  position: absolute;
  display:inline-block;
  font-family: 'Josefin Sans', sans-serif;

} 

.squarefeet-rate{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Josefin Sans', sans-serif;
  color: white;
  padding: 15px;
  margin-left: 3rem;
  margin-top: 6rem;
  display: inline-block;
  position: absolute;  
}

.bottom_feet_one{
   margin-left: 20rem;
   margin-bottom: 2rem;
   margin-top: 3rem;
   text-align: center ;
   display: inline-block;
   text-align: center;
   font-family: 'Josefin Sans', sans-serif;
   padding: 30px;
   position: absolute;
   color: black;
   border: 1px solid white;
   border-radius: 10px ;
   background-color: white;
}

.bottom_feet_two{
   margin-left: 20rem;
   margin-bottom: 3rem;
   margin-top: 14rem;
   font-family: 'Josefin Sans', sans-serif;
   text-align: center ;
   display: inline-block;
   text-align: center;
   padding: 30px;
   position: absolute;
   color: black;
   background-color:white;
   border: 1px solid white;
   border-radius: 10px;
}

.prize_bottom{
  margin-top: -12rem;
}

.fieldset-prize{
  margin-top: 6.5rem;
  margin-left: 13%;
  margin-right: 4%;
  margin-bottom: 20%;
  border: 1px solid black;
  border-radius: 30px;
  width: 75%;
  box-shadow: 10px -5px 30px black;
  background-color: rgb(36, 17, 103);
}

/* css for sign board */

.square{
  border: 1px solid black;
  display: grid;
  place-content: center;
  margin: auto;
  color:white;
  margin-top: 6% ;
  margin-bottom: 3%;
}

/* input for sign board  */

input{
  margin-top: 1rem;
  width: 14%;
  border: 1px solid white;
  padding: 10px;
  font-family: 'Josefin Sans', sans-serif;
  border-radius: 10px;
  color: black;
}

input::placeholder{
  color: black;
  text-transform: capitalize;
}

.label-design {
  text-transform: capitalize;
  margin-left: 2rem;
  font-family: monospace;
  font-size: 16px;
  padding: 10px;
  border: 1px solid white;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
}

.label-design:hover{
  background-color: white;
  color: black;
}

.optionTag{
  margin-top: 1rem;
  width: 16%;
  padding: 10px;
  border-radius: 10px;
  text-transform: capitalize;
  border: 1px solid white;
  font-family: 'Josefin Sans', sans-serif;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 13%, rgba(34,24,106,1) 83%);
}

/* header for sign components  */
header {
    margin-bottom: -20px;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 10px;
    background-color: white;
    box-shadow: -10px -10px 10px black;
    color: black;
    padding: 20px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: x-large;
    font-family: 'Josefin Sans', sans-serif;
    word-spacing: 5px;
    letter-spacing: 5px;
}

/* footer for all */
footer {
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 50px;
    border: 1px solid black;
    background-color: blue;
    box-shadow: inset 1px -1px 30px yellow ;
    color: white;
    padding: 20px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: x-large;
    font-family:Georgia, 'Times New Roman', Times, serif;
    word-spacing: 5px;
    letter-spacing: 5px;
    margin-top: 40px;
    margin-bottom: 20px;
}

option{
  color: black;
}

.total-sign{
  padding: 30px;
  border: none;
}

@media (max-width: 768px) {
  body {
    background-color: gold;
    margin: 0;
    padding: 0;
    box-shadow: inset 10px 10px 100px #333;
  }
  /* top list code  */
  .topList {
        text-transform: capitalize;
        background-color: transparent ;
        top: 0%;
        left: 0%;
        right: 0%;
       
  }
  
  .ul-list {
    color: black;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-align: end;
    margin-bottom: 2rem; 
  }
  
  li{
  
    list-style-type: none;
    display: inline-block;
    padding: 20px;
    margin-inline-end: 30px;
    
  }
  
  li:hover {
   border-bottom: 1px solid #333;
    color: black;
  
  }
  
  li:active{
    background-color: gold;
    color: black;
  }
  
  .sign-board{
  
     font-family:Comic Sans MS;
     font-weight: bolder;
     text-transform: uppercase;
     word-spacing: 4px;
     font-size: xx-large;
     padding: 2rem;
     justify-content: center;
     display: inline;
     color: white;
     margin-left:10px;
     text-shadow: 1px 1px 10px black;
     margin-bottom: 10px;
     border-radius: 10px;
  }
  
  .header{
    text-align: center;
    
  }
  
  /* CSS for chain container */
  .chain-container {
    display: flex;
    justify-content: center;
  }
  
  /* CSS for chain boxes */
  .chain-box {
    animation: chain-animation 1s infinite alternate; /* Using animation for chain effect */
  }
  
  /* CSS animation keyframes for the chain effect */
  @keyframes chain-animation {
    0% {
      transform: scale(1.5);
     background-color: transparent;
    }
    100% {
      transform: scale(2);
      background-color: transparent;
     
    }
    
  }
  .straight-line {
      border-top: 2px solid black; /* Adjust the width and color as desired */
      width: 500px;
      margin: 20px auto; /* Center the line on the page */
  }
  .bulb-image {
    min-height: 3rem;
  }
  .email{
    background-color: transparent;
    color: blue;
    margin-right: 70%;
    justify-content: center;
    text-decoration: none;
    text-transform: lowercase;
    display: flex;
    margin-top: -91px;
    padding: 20px;
    justify-content: flex-start;
    font-weight: bolder;
  }
  .email:hover{
    border-bottom: 1px solid #333;
    color: red
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
  
  /* quotes components  */
  .quotes{
      margin-top:-3rem ;
      font-size: 0.75in;
      text-transform: uppercase;
      font-weight: bold;
      font-family: 'Limelight', cursive;
      margin-left: 0%;
      margin-right: 10%;
      display: flex;
      margin-bottom: 10px;
      background-color: transparent;
      margin-left: 10%;
    
  }
  /* quotes componenets  */
   .para-q{
      margin-top: -4rem ;
      font-family: monospace;
      text-transform:none ;
      font-size: 16px;
      font-weight: lighter;
      text-align :justify;
      width: 70%;
      text-shadow: 0; 
      margin-left: 14%;
  } 
  /* quotes for image  */
  .content {
    justify-content: center;
    text-align: center;
    background-color: transparent;
    
  }
  .content:hover{
    text-shadow: 3px 3px 10px white;
  
  }
  /* qoutes company logo design  */
  .image {
      margin-left: 10%; /* Optional: Add some space between the content and image */
      height: 300px;
      width:  450px;
      margin-top: 7%;
      filter: drop-shadow( 19px -10px 0px black);
    }
  
    .image:hover{
      filter: drop-shadow( 19px -10px 0px #333);
      
    }
  
  .features{
      text-align:justify;
      width: 100%;
      letter-spacing: 1px;
      word-spacing: 5px;
      font-size: 20px;
      margin-left: 10px;
      text-indent: 10px;
  }
  .features p{
      text-indent: 10px;
  }
  
  .head-head{
      margin-bottom: 7%;
      margin-top: 7%;
      text-align: center;
      text-transform: uppercase;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-size: xx-large; 
  
  }
  
  /* prizing input  */
  .prizing {
      padding: 10px;
      background-color: transparent;
      font-size: 16px;
      text-align:center;
      color: black;
      margin-left: 1%;
      margin-bottom: 15px;
      border: transparent;
      border-bottom: 2px solid black;
  }
  
  .prize {
    font-family: monospace;
    text-transform: uppercase;
    font-size: 20px;
  }
  
  
  /* prizing form */
  .form{
    text-transform: uppercase;
    word-spacing: 3px;
    font-size: 20px;
    text-align: left;
    font-family: monospace;
  
  }
  
  .button{
    font-family: monospace;
    padding :10px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: inset 1px 1px 10px black;
    background-color: transparent;
    
  }
  
  .button:hover{
    background-color: white;
    color: black;
  }
  
  .totalfeet,.totalRate{
    text-transform: uppercase;
    border:1px solid black;
    background-color: gold;
    box-shadow: inset 1px 1px 10px black;
    padding: 10px;
    text-align: center;
    font-size: x-large;
    font-family: monospace;
    width: 50%;
    margin-left: 24%;
  }
  
  
  .head-head-prize {
    margin-bottom: 3%;
    margin-top: 3%;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: xx-large;  
  }
  .squarefeet-rate{
    text-align: center;
    text-transform: uppercase;
    border: 1px solid black;
    padding: 10px;
    background-color: gold ;
    box-shadow: inset 1px 1px 10px black;
    font-family: monospace;
    font-size: x-large;
  }
  .bold{
    font-weight: bolder;
    font-size: xx-large;
  }
  legend{
    text-align: left;
    text-transform: uppercase;
    font-family: monospace;
    font-size: xx-large;
    font-style: italic;
  
  }
  .fieldset-prize{
    margin-top: 10%;
    background-color: aliceblue;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 7%;
  }
  
  /* css for sign board */
  
  
  .square{
    border: 1px solid black;
    display: grid;
    place-content: center;
    margin: auto;
    color: white;
    margin-top: 6% ;
    margin-bottom: 3%;
  }
  
  /* input for sign board  */
  
  input{
    margin-top: 1rem;
    width: 14%;
    padding: 10px;
    background-color: gold;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  
  }
  
  .label-design {
    text-transform: capitalize;
    margin-left: 2rem;
    font-family: monospace;
    padding: 10px;
    border: 1px solid black;
    background-color: gold;
    color: black;
    
  }
  .label-design:hover{
    background-color: white;
  }
  
  .optionTag{
    margin-top: 1rem;
    width: 16%;
    padding: 10px;
    background-color: gold;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }
  
  
  /* header for sign components  */
  header {
      margin-bottom: -20px;
      margin-left: 5%;
      margin-right: 5%;
      border-radius: 50px;
      border: 1px solid black;
      background-color: blue;
      box-shadow: inset 1px -1px 30px yellow ;
      color: white;
      padding: 20px;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: x-large;
      font-family:Georgia, 'Times New Roman', Times, serif;
      word-spacing: 5px;
      letter-spacing: 5px;
  }
  /* footer for all */
  footer {
  
      margin-left: 5%;
      margin-right: 5%;
      border-radius: 50px;
      border: 1px solid black;
      background-color: blue;
      box-shadow: inset 1px -1px 30px yellow ;
      color: white;
      padding: 20px;
      text-align: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: x-large;
      font-family:Georgia, 'Times New Roman', Times, serif;
      word-spacing: 5px;
      letter-spacing: 5px;
      margin-top: 40px;
      margin-bottom: 20px;
  }
  option{
    color: black;
  }
  
  .total-sign{
    padding: 30px;
  
  }

}