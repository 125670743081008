.features-page {
    padding: 20px;
    background-color:rgb(237, 237, 235);
  }
  
  .features-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 36px;
  }
  
  .feature-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-align: center;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
  }
  
  .feature-image {
    border-radius: 10px 10px 0 0;
    height: 200px;
    object-fit: cover;
  }
  